import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

import SectionContainer from '../../SectionContainer'

import ChevronRight from '../../../assets/svg/chevronRight.svg'
import ChevronLeft from '../../../assets/svg/chevronLeft.svg'


import sectionData from "../../../pages-data/about/massmedia"

import styles from './styles.module.styl'



export default function SectionAboutMassMedia() {
  return (
    <StaticQuery
      query={graphql`
      query {
        biz360: file(relativePath: { eq: "massmedia/biz360.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        delovayasreda: file(relativePath: { eq: "massmedia/delovayasreda.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        habr: file(relativePath: { eq: "massmedia/habr.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        layfhaker: file(relativePath: { eq: "massmedia/layfhaker.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        malybiznes: file(relativePath: { eq: "massmedia/malybiznes.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        medialeaks: file(relativePath: { eq: "massmedia/medialeaks.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        rbk: file(relativePath: { eq: "massmedia/rbk.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        restoclub: file(relativePath: { eq: "massmedia/restoclub.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        restoranoved: file(relativePath: { eq: "massmedia/restoranoved.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        probiznes: file(relativePath: { eq: "massmedia/probiznes.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        retail: file(relativePath: { eq: "massmedia/retail.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        rusbase: file(relativePath: { eq: "massmedia/rusbase.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        vc: file(relativePath: { eq: "massmedia/vc.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        biz360_hover: file(relativePath: { eq: "massmedia/biz360_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        delovayasreda_hover: file(relativePath: { eq: "massmedia/delovayasreda_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        habr_hover: file(relativePath: { eq: "massmedia/habr_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        layfhaker_hover: file(relativePath: { eq: "massmedia/layfhaker_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        malybiznes_hover: file(relativePath: { eq: "massmedia/malybiznes_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        medialeaks_hover: file(relativePath: { eq: "massmedia/medialeaks_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        rbk_hover: file(relativePath: { eq: "massmedia/rbk_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        restoclub_hover: file(relativePath: { eq: "massmedia/restoclub_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        restoranoved_hover: file(relativePath: { eq: "massmedia/restoranoved_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        probiznes_hover: file(relativePath: { eq: "massmedia/probiznes_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        retail_hover: file(relativePath: { eq: "massmedia/retail_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        rusbase_hover: file(relativePath: { eq: "massmedia/rusbase_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        vc_hover: file(relativePath: { eq: "massmedia/vc_hover.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        
      }
    `}
      render={data => {

        function NextArrow(props) {
          const { className, style, onClick } = props;
          return (
            <div
              className={className}
              style={style}
              onClick={onClick}
            >
              <ChevronRight/>
            </div>
          )
        }

        function PrevArrow(props) {
          const { className, style, onClick } = props;
          return (
            <div
              className={className}
              style={style}
              onClick={onClick}
            >
              <ChevronLeft/>
            </div>
          )
        }

        const sliderSetting = {
          slidesToShow: 3,
          rows: 2,
          nextArrow: <NextArrow/>,
          prevArrow: <PrevArrow/>,
          dotsClass: `slick-dots ${styles.media__sliderDots}`
        }

        const mobileSliderSetting = {
          slidesToShow: 1,
          rows: 3,
          dots: true,
          centerMode: true,
          centerPadding: '14px',
          dotsClass: `slick-dots ${styles.media__sliderDots}`
        }

        const tabletSliderSetting = {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2,
          dots: true,
          dotsClass: `slick-dots ${styles.media__sliderDots}`
        }

        return (

          <SectionContainer className={styles.mediaContainer}>
            <section className={styles.media}>

              <span className={styles.media__header__firstPart}>
                {sectionData.header}
              </span>

              {/* Слайдер с статьями */}
              <Slider
                className={styles.media__reviewsList}
                {...sliderSetting}
              >

                {
                  sectionData.media.map((media, index) => (
                    <a
                      className={styles.media__review_Container}
                      key={`${media.image}_${index}`}
                      href={media.href}
                      target='_blank'
                    >

                      <div
                        className={styles.media__review_back}
                      >
                        <GatsbyImage
                          image={data[media.image + '_hover'].childImageSharp.gatsbyImageData}
                          alt={media.alt}
                          style={{
                            width: media.width + 'px'
                          }}
                        />

                        <p className={styles.media__review__text}>
                          {media.text}
                        </p>
                      </div>
                      <div
                        className={styles.media__review}
                      >
                        <GatsbyImage
                          image={data[media.image].childImageSharp.gatsbyImageData}
                          alt={media.alt}
                          style={{
                            width: media.width + 'px'
                          }}
                        />
                      </div>
                    </a>
                  ))
                }

              </Slider>

              <Slider
                className={styles.media__reviewsList_mobile}
                {...mobileSliderSetting}
              >
                {
                  sectionData.media.map((media, index) => (
                    <a
                      className={styles.media__review_Container}
                      key={`${media.image}_${index}`}
                      href={media.href}
                      target='_blank'
                    >
                      <div className={styles.media__review}>
                        <GatsbyImage
                          image={data[media.image].childImageSharp.gatsbyImageData}
                          alt={media.alt}
                          style={{
                            width: media.width + 'px'
                          }}
                        />
                        <p className={styles.media__review__text}>
                          {media.text}
                        </p>
                      </div>
                    </a>
                  ))
                }

              </Slider>

              <Slider
                className={styles.media__reviewsList_tablet}
                {...tabletSliderSetting}
              >
                {
                  sectionData.media.map((media, index) => (
                    <a
                      className={styles.media__review_Container}
                      key={`${media.image}_${index}`}
                      href={media.href}
                      target='_blank'
                    >
                      <div
                        className={styles.media__review}
                      >
                        <GatsbyImage
                          image={data[media.image].childImageSharp.gatsbyImageData}
                          alt={media.alt}
                          style={{
                            width: media.width + 'px'
                          }}
                        />
                        <p className={styles.media__review__text}>
                          {media.text}
                        </p>
                      </div>
                    </a>
                  ))
                }

              </Slider>

            </section>
          </SectionContainer>
        )
      }}
    />
    )
}
