import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import SectionContainer from '../../SectionContainer'

import sectionData from "../../../pages-data/about/expo"

import styles from './styles.module.styl'


export default function SectionAboutExpo() {
  return (
    <StaticQuery
      query={graphql`
          query {
            pir: file(relativePath: { eq: "expo/pir.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE)}
            }
            foodservice: file(relativePath: { eq: "expo/foodservice.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE width: 238)}
            }
            buybrand: file(relativePath: { eq: "expo/buybrand.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            horeca: file(relativePath: { eq: "expo/horeca.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            gastreet: file(relativePath: { eq: "expo/gastreet.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            megusto: file(relativePath: { eq: "expo/megusto.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            ifff: file(relativePath: { eq: "expo/ifff.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            metroexpo: file(relativePath: { eq: "expo/metroexpo.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            taste: file(relativePath: { eq: "expo/taste.png" }) {
              childImageSharp {gatsbyImageData( placeholder: NONE )}
            }
            
           }
          `}
      render={data => {

        //TODO
        let images = {}

        sectionData.expo.forEach(
          expo => images[expo.image] = data[expo.image].childImageSharp.gatsbyImageData
        )

        return (
          <SectionContainer className={styles.expoContainer}>
            <section className={styles.expo}>

              <div className={styles.expo__header}>{sectionData.header}</div>

              <div className={styles.expo__categories}>

                {
                  sectionData.expo.map(expo => (
                    <div
                      className={styles.expo_imageContainer}
                      key={expo.image}
                    >
                      <GatsbyImage
                        image={images[expo.image]}
                        alt={expo.image}
                        style={{ width: (expo.mobile ? expo.mobile : expo.width) + 'px' }}
                      />
                    </div>
                  ))
                }

              </div>

            </section>
          </SectionContainer>

        )
      }}
    />
  )
}
