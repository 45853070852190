import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

import SectionContainer from '../../SectionContainer'

import ChevronRight from '../../../assets/svg/chevronRight.svg'
import ChevronLeft from '../../../assets/svg/chevronLeft.svg'

import sectionData from "../../../pages-data/about/ecosystemApplications"

import styles from './styles.module.styl'


export default function SectionAboutEcosystemApplications() {
  return (
    <StaticQuery
      query={graphql`
      query {
        wl: file(relativePath: { eq: "apps/wl.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        qrm: file(relativePath: { eq: "apps/qrm.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        qrDefault: file(relativePath: { eq: "apps/qrDefault.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        kds: file(relativePath: { eq: "apps/kds.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        service: file(relativePath: { eq: "apps/service.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        showcase: file(relativePath: { eq: "apps/showcase.png" }) {
          childImageSharp {gatsbyImageData( placeholder: NONE )}
        }
        
      }
    `}
      render={data => {

        if (!sectionData.populated) {
          sectionData.apps.forEach(
            app => app.tabImage = data[app.tabImage].childImageSharp.gatsbyImageData
          )

          sectionData.populated = true
        }


        function NextArrow(props) {
          const { className, style, onClick } = props;
          return (
            <div
              className={className}
              style={style}
              onClick={onClick}
            >
              <ChevronRight/>
            </div>
          )
        }

        function PrevArrow(props) {
          const { className, style, onClick } = props;
          return (
            <div
              className={className}
              style={style}
              onClick={onClick}
            >
              <ChevronLeft/>
            </div>
          )
        }

        const sliderSetting = {
          slidesToShow: 4,
          nextArrow: <NextArrow/>,
          prevArrow: <PrevArrow/>,
        }

        const mobileSliderSetting = {
          slidesToShow: 1,
          dots: true,
          centerMode: true,
          centerPadding: '5px'
        }

        const tabletSliderSetting = {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        }

        return (
          <SectionContainer className={styles.ecosystemAppContainer}>
            <section className={styles.ecosystemApp}>

              <h2 className={styles.ecosystemApp__header}>
                {sectionData.header}
              </h2>

              {/* Слайдер c приложениями */}
              <Slider
                className={styles.ecosystemApp__appsList}
                {...sliderSetting}
              >
                {
                  sectionData.apps.map((app) => (
                    <div
                      key={app.title}
                      className={styles.ecosystemApp__app}
                    >
                      <div className={styles.ecosystemApp__app_logoContainer}>
                        <GatsbyImage
                          className={styles.ecosystemApp__app_logo}
                          image={app.tabImage}
                          alt=''
                        />
                      </div>

                      <div className={styles.ecosystemApp__app__mainInfo}>

                        {/* Обертка для выравнивания */}
                        <p className={styles.ecosystemApp__app__name}>
                          {app.title}
                        </p>

                      </div>

                      <p className={styles.ecosystemApp__app__text}>
                        {app.text}
                      </p>

                    </div>
                  ))
                }
              </Slider>

              <Slider
                className={styles.ecosystemApp__appsList_mobile}
                {...mobileSliderSetting}
              >

                {
                  sectionData.apps.map((app) => (
                    <div
                      key={app.title + '_mobile'}
                      className={styles.ecosystemApp__app}
                    >
                      <div className={styles.ecosystemApp__app_logoContainer}>
                        <GatsbyImage
                          className={styles.ecosystemApp__app_logo}
                          image={app.tabImage}
                          alt=''
                        />
                      </div>

                      <div className={styles.ecosystemApp__app__mainInfo}>
                        {/* Обертка для выравнивания */}
                        <p className={styles.ecosystemApp__app__name}>
                          {app.title}
                        </p>
                      </div>
                      <p className={styles.ecosystemApp__app__text}>
                        {app.text}
                      </p>
                    </div>
                  ))
                }

              </Slider>

              <Slider
                className={styles.ecosystemApp__appsList_tablet}
                {...tabletSliderSetting}
              >

                {
                  sectionData.apps.map((app) => (
                    <div
                      key={app.title + '_mobile'}
                      className={styles.ecosystemApp__app}
                    >
                      <div className={styles.ecosystemApp__app_logoContainer}>
                        <GatsbyImage
                          className={styles.ecosystemApp__app_logo}
                          image={app.tabImage}
                          alt=''
                        />
                      </div>

                      <div className={styles.ecosystemApp__app__mainInfo}>
                        {/* Обертка для выравнивания */}
                        <p className={styles.ecosystemApp__app__name}>
                          {app.title}
                        </p>
                      </div>
                      <p className={styles.ecosystemApp__app__text}>
                        {app.text}
                      </p>
                    </div>
                  ))
                }

              </Slider>

            </section>
          </SectionContainer>
        )
      }}
    />
    )
}
