import React from 'react'

import SectionContainer from '../../SectionContainer'

import sectionData from '../../../pages-data/about/evolution'

import styles from './styles.module.styl'

import Path from '../../../assets/svg/stepPath.svg'
import StraightPath from '../../../assets/svg/straightPath.svg'


const SectionAboutEvolution = () => {

  return (
    <SectionContainer className={styles.evolutionContainer}>
      <section className={styles.evolution}>

        <div className={styles.evolution__header}>
          {sectionData.sectionTitle}
        </div>

        <div className={styles.evolution__stepsBlock}>
          {sectionData.steps.map((fragment, index) => (
              <div
                className={`
                  ${styles.evolution__stepContainer}
                  ${index % 2 === 0 ? styles.right : null}
                `}
                key={fragment.year}
              >
                <div className={`
                ${styles.evolution__stepContainer_yearBox} ${index % 2 === 0 ? styles.right : null}
                `}
                >
                  {fragment.year}
                </div>
                {fragment.desc}
                <Path
                  className={`
                    ${styles.evolution__stepContainer_path}
                    ${index % 2 === 0 ? styles.right : null}
                    ${index === Object.keys(sectionData.steps).length - 1 ? styles.last : null}
                  `}
                />
                <StraightPath
                  className={`
                    ${styles.evolution__stepContainer_straightPath}
                    ${index === Object.keys(sectionData.steps).length - 1 ? styles.last : null}
                  `}
                />
              </div>
            )
          )}
        </div>

      </section>
    </SectionContainer>
  )
}
export default SectionAboutEvolution
