export default {
	header: "Участвуем в выставках",
	expo: [
		{
			image: "pir",
			width: 220,
		},
		{
			image: "foodservice",
			width: 238,
		},
		{
			image: "buybrand",
			width: 310,
		},
		{
			image: "horeca",
			width: 260,
		},
		{
			image: "gastreet",
			width: 250,
		},
		{
			image: "megusto",
			width: 280,
		},
		{
			image: "ifff",
			width: 178,
		},
		{
			image: "metroexpo",
			width: 110,
		},
		{
			image: "taste",
			width: 135,
		},
	],
}
