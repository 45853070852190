import React from 'react'

import { Link } from 'gatsby'

import SectionContainer from '../../SectionContainer'

import ClientsIcons from '../../ClientsIcons'

import sectionData from '../../../pages-data/about/clients'

import styles from './styles.module.styl'



const SectionAboutClients = () => {

  return (
    <SectionContainer className={styles.clientsContainer}>
      <section className={styles.clients}>
        <div className={styles.clients__informationContainer}>
          <h2
            className={styles.clients__title_mobile}
          >
            {sectionData.title}
          </h2>

          <div className={styles.clients__circle}>
            <div className={styles.clients__innerCircle}>
              <div className={styles.clients__innerCircle_firstParagraph}>
                Каждый день более
              </div>
              <div className={styles.clients__innerCircle_number}>
                20
              </div>
              <div className={styles.clients__innerCircle_secondParagraph}>
                рестораторов выбирают <b>Quick Resto</b> для своего бизнеса
              </div>
            </div>
          </div>

          <div className={styles.clients__textColumn}>

            <h2
              className={styles.clients__title}
            >
              {sectionData.title}
            </h2>

            <p className={styles.clients__description}>
              {sectionData.descriptionFirstPart}
            </p>

            <p className={styles.clients__description}>
              {sectionData.descriptionSecondPart}
              <a
                className={styles.clients__link}
                href={sectionData.postLinks.metallurg.href}
              >
                {sectionData.postLinks.metallurg.text}
              </a>
            </p>

            <p className={styles.clients__description}>
              {sectionData.descriptionThirdPart}
            </p>
          </div>
        </div>


        <p className={styles.clients__description_mobile}>
          {sectionData.descriptionFirstPart}
        </p>

        <div className={styles.clients_icons}>
         <ClientsIcons/>
        </div>
        <p className={styles.clients__description_mobile}>
          {sectionData.descriptionSecondPart}
          <Link
            className={styles.clients__link}
            to={sectionData.postLinks.metallurg.href}
          >
            {sectionData.postLinks.metallurg.text}
          </Link>
        </p>

        <p className={styles.clients__description_mobile}>
          {sectionData.descriptionThirdPart}
        </p>

      </section>
    </SectionContainer>
  )
}
export default SectionAboutClients
