import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import SectionContainer from '../../SectionContainer'

import sectionData from '../../../pages-data/about/presentation'

import styles from './styles.module.styl'



export default function SectionAboutPresentation() {
        return (
          <SectionContainer className={styles.aboutProductPresentation__sectionContainer}>
            <section className={styles.aboutProductPresentation}>

              <h1 className={styles.aboutProductPresentation__header}>
                {sectionData.header}
              </h1>

              <h2 className={styles.aboutProductPresentation__header_mobile}>
                {sectionData.mobileHeader}
              </h2>

              <div className={styles.aboutProductPresentation__description_mobile}>
                  <div className={styles.presentation__description_text}>{sectionData.text[0]}</div>
              </div>


              <StaticImage
                className={styles.aboutProductPresentation__img}
                src='../../../assets/images/aboutPresentation.png'
                alt={sectionData.alt}
                placeholder='none'
              />

              <StaticImage
                className={styles.aboutProductPresentation__img_mobile}
                src='../../../assets/images/aboutPresentation_mobile.png'
                alt={sectionData.alt}
                placeholder='none'
              />

              <div className={styles.aboutProductPresentation__description_mobile}>
                {sectionData.text.slice(1).map((paragraph, index) => (
                  <div
                    className={styles.presentation__description_text}
                    key={index}
                  >
                    {paragraph}
                  </div>
                ))}
              </div>

              <div className={styles.aboutProductPresentation__description}>
                {sectionData.text.map((paragraph, index) => (
                  <div
                    className={styles.presentation__description_text}
                    key={index}
                  >
                    {paragraph}
                  </div>
                ))}
            </div>
            </section>
          </SectionContainer>
        )
}
