export default {
	header: "Экосистема для общепита",
	sliderCenterPadding: "24px",
	slideImageWidth: "311px",
	apps: [
		{
			tabImage: "qrDefault",
			sliderImage: "qrDefault",
			title: "Кассовый терминал",
			text:
				"Работает с онлайн-кассой, схема зала, мобильный склад, подсказки официантам",
		},
		{
			tabImage: "qrDefault",
			sliderImage: "qrDefault",
			title: "Бэк-офис",
			text:
				"CRM, номенклатура, аналитика, инструменты контроля, доступ из любой точки мира",
		},
		{
			tabImage: "wl",
			sliderImage: "wl",
			title: "Приложение для гостей",
			text:
				"Бесплатная разработка, брендированный дизайн, предзаказ, push-уведомления, доставка",
		},
		{
			tabImage: "qrm",
			sliderImage: "qrm",
			title: "Приложение для руководителя",
			text:
				"Аналитика в смартфоне, кассовые смены, количество гостей, топ продаж",
		},
		{
			tabImage: "kds",
			sliderImage: "kds",
			title: "Экран для повара",
			text: "Обратная связь с официантом, таймер приготовления блюд",
		},
		{
			tabImage: "showcase",
			sliderImage: "showcase",
			title: "Интернет-витрина",
			text: "Бесплатный сайт вашего заведения с меню и акциями",
		},
		{
			tabImage: "service",
			sliderImage: "service",
			title: "Сервис",
			text:
				"Aвтоматизация под ключ, аудит бизнеса, формирование отчётности, регистрация онлайн-кассы",
		},
	],
}
