export default {
	header: "О нас в СМИ",
	media: [
		{
			image: "biz360",
			href:
				"https://biz360.ru/materials/avtomatizatsiya-v-obshchepite-kak-rabotaet-servis-upravleniya-restoranami-i-kafe/?sphrase_id=66765",
			text:
				"Автоматизация в общепите: как работает сервис управления ресторанами и кафе",
			width: 251,
		},
		{
			image: "rusbase",
			href: "https://rb.ru/longread/quick-resto/",
			text: "Что ждет ресторанный бизнес с полным переходом на онлайн-кассы?",
			width: 235,
		},
		{
			image: "vc",
			href: "https://vc.ru/tribuna/5292-quick-resto",
			text: "Облачная система автоматизации ресторанного бизнеса Quick Resto",
			width: 176,
		},
		{
			image: "delovayasreda",
			href: "https://dasreda.ru/learn/blog/article/399",
			text: "Как привлечь клиентов в ресторан",
			width: 273,
		},
		{
			image: "rbk",
			href: "http://quickresto.rbc.ru/",
			text: "Современный ресторанный бизнес. Как начать и преуспеть",
			width: 187,
		},
		{
			image: "habr",
			href: "https://habr.com/ru/company/quickresto/blog/381001/",
			text: "Обзор Quick Resto: как создать и автоматизировать свой ресторан",
			width: 136,
		},
		{
			image: "layfhaker",
			href: "https://lifehacker.ru/quickresto-quiz/",
			text: "Готовы ли вы открыть свой ресторан?",
			width: 268,
		},
		{
			image: "malybiznes",
			href: "http://mbgazeta.ru/tag/restoran-avtomatizatsiya/",
			text: "Результат одной кнопкой: зачем нужна автоматизация ресторанов",
			width: 192,
		},
		{
			image: "medialeaks",
			href: "https://medialeaks.ru/garbage-party/",
			text: "Поймёшь ли ты ресторанный сленг?",
			width: 245,
		},
		{
			image: "restoclub",
			href:
				"https://www.restoclub.ru/review/chiefs/terrasa-pri-restorane-kak-jeto-ustroeno",
			text: "Терраса при ресторане: взгляд изнутри",
			width: 269,
		},
		{
			image: "restoranoved",
			href:
				"http://restoranoved.ru/articles/avtomatizatsiya-bystro-tochno-udobno-nadezhno/",
			text: "Автоматизация: быстро, точно, удобно, надёжно",
			width: 255,
		},
		{
			image: "probiznes",
			href:
				"https://probusiness.io/press/3717-quick-resto-avtomatizaciya-obshchestvennogo-pitaniya.html",
			text:
				"Quick Resto ведёт учёт в кафе, барах и ресторанах за 2 рубля в день",
			width: 211,
		},
		{
			image: "restoclub",
			href:
				"https://www.restoclub.ru/review/experts/chto-nuzhno-znat-ob-onlajn-kassah",
			text: "Что нужно знать об онлайн-кассах",
			width: 269,
		},
		{
			image: "retail",
			href:
				"https://www.retail.ru/articles/kak-v-pogone-za-novymi-gostyami-ne-rasteryat-postoyannykh-vo-vremya-chm-po-futbolu/",
			text:
				"Как в погоне за новыми гостями не растерять постоянных во время ЧМ по футболу",
			width: 255,
		},
	],
}
