export default {
	sectionTitle: "Как мы развивались",
	steps: [
		{
			year: "2013",
			desc: "Основание компании",
		},
		{
			year: "2014",
			desc: "Стали резидентом Сколково",
		},
		{
			year: "2015",
			desc: "Вышли на рынок СНГ",
		},
		{
			year: "2016",
			desc: "В состав владельцев группы компаний Quick Resto вошла фирма «1С»",
		},
		{
			year: "2017",
			desc:
				"Вышли на международный уровень и стали появляться в заведениях Австралии, Египта, ОАЭ, Шри-Ланки, Испании, Эстонии, Литвы, Чехии",
		},
		{
			year: "2019",
			desc: "Преодолели планку в 5 000 активных клиентов",
		},
		{
			year: "2020",
			desc:
				"Создали  сервисный формат обслуживания клиентов. Обеспечиваем устойчивое развитие вашего бизнеса — помогаем выстраивать бизнес-процессы и обучаем работе с системой",
		},
	],
}
