import React from 'react'
import { requestForPersonalOffer } from '../../apiRequests/callToAction'
import OldVersionLayout from '../../layout/oldVersion'
import SectionAboutPresentation from '../../components/sections/SectionAboutPresentation'
import SectionAboutEcosystemApplications
  from '../../components/sections/SectionAboutEcosystemApplications'
import SectionAboutClients from '../../components/sections/SectionAboutClients'
import SectionAboutEvolution from '../../components/sections/SectionAboutEvolution'
import SectionAboutMassMedia from '../../components/sections/SectionAboutMassMedia'
import SectionAboutExpo from '../../components/sections/SectionAboutExpo'
import SectionPhoneCallRequest from '../../components/sections/SectionPhoneCallRequest'

import about from '../../assets/images/aboutPresentation.png'

import complexOfferCTAData from '../../pages-data/index/evaluate'
import Breadcrumbs from '../../components/Breadcrumbs'

export default function PageAbout() {
  const metaTags = [
    {
      name: 'description',
      content: 'Quick Resto стремится сделать систему автоматизации удобной и доступной для всех, кто работает в сфере обслуживания, использовать накопленный опыт и знания IT-технологий для создания инновационного продукта.',
    },
    // {
    //   name: 'keywords',
    //   content: 'компания, quick resto, автоматизация, ресторанный бизнес, 1с, программа, приложение, общепит',
    // },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/about_company/',
    },
    {
      property: 'og:title',
      content: 'О компании Quick Resto: эксперте в сфере ресторанного бизнеса',
    },
    {
      property: 'og:description',
      content: 'Quick Resto стремится сделать систему автоматизации удобной и доступной для всех, кто работает в сфере обслуживания, использовать накопленный опыт и знания IT-технологий для создания инновационного продукта.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${about}`,
    },
  ]

  return (
    <OldVersionLayout
      title="О компании Quick Resto: эксперте в сфере ресторанного бизнеса"
      metaTags={metaTags}
      linkCanonical="https://quickresto.ru/about_company/"
    >
      <Breadcrumbs />

      <SectionAboutPresentation />

      <SectionAboutEcosystemApplications />

      <SectionAboutClients />

      <SectionAboutEvolution />

      <SectionAboutMassMedia />

      <SectionAboutExpo />

      <SectionPhoneCallRequest
        data={complexOfferCTAData}
        ctaAction={requestForPersonalOffer}
        horizontal
      />
    </OldVersionLayout>
  )
}
