export default {
	header: "О компании Quick Resto",
	mobileHeader: "О Quick Resto",
	alt: "О компании Quick Resto - фотография 1",
	text: [
		"Quick Resto создаёт программные продукты для рестораторов с 2013 года. За это время мы стали резидентом Сколково, вышли на международный уровень и выросли в экосистему, в которой есть всё для управления современным ресторанным бизнесом.",
		"Наши продукты помогают привлекать гостей, планировать выручку, контролировать персонал и следить за складом. Нас выбирают, потому что система проста в использовании и к ней не нужно покупать дорогое «железо».",
		"У нас в команде 70+ сотрудников. Разработчики постоянно совершенствуют систему, техподдержка — всегда на связи, а консультанты по бизнесу помогают получать больше прибыли с помощью автоматизации.",
	],
}
