import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

import styles from "./styles.module.styl"

export default function ClientsIcons() {
	return (
		<StaticQuery
			query={graphql`
				query {
					clientIcon01: file(
						relativePath: { eq: "clientsIcons/clientIcon01.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon02: file(
						relativePath: { eq: "clientsIcons/clientIcon02.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon03: file(
						relativePath: { eq: "clientsIcons/clientIcon03.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon04: file(
						relativePath: { eq: "clientsIcons/clientIcon04.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon05: file(
						relativePath: { eq: "clientsIcons/clientIcon05.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon06: file(
						relativePath: { eq: "clientsIcons/clientIcon06.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon07: file(
						relativePath: { eq: "clientsIcons/clientIcon07.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon08: file(
						relativePath: { eq: "clientsIcons/clientIcon08.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon09: file(
						relativePath: { eq: "clientsIcons/clientIcon09.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon10: file(
						relativePath: { eq: "clientsIcons/clientIcon10.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon11: file(
						relativePath: { eq: "clientsIcons/clientIcon11.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon12: file(
						relativePath: { eq: "clientsIcons/clientIcon12.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon13: file(
						relativePath: { eq: "clientsIcons/clientIcon13.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon14: file(
						relativePath: { eq: "clientsIcons/clientIcon14.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon15: file(
						relativePath: { eq: "clientsIcons/clientIcon15.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon16: file(
						relativePath: { eq: "clientsIcons/clientIcon16.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon17: file(
						relativePath: { eq: "clientsIcons/clientIcon17.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon18: file(
						relativePath: { eq: "clientsIcons/clientIcon18.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon19: file(
						relativePath: { eq: "clientsIcons/clientIcon19.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon20: file(
						relativePath: { eq: "clientsIcons/clientIcon20.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon21: file(
						relativePath: { eq: "clientsIcons/clientIcon21.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon22: file(
						relativePath: { eq: "clientsIcons/clientIcon22.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon23: file(
						relativePath: { eq: "clientsIcons/clientIcon23.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon24: file(
						relativePath: { eq: "clientsIcons/clientIcon24.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon25: file(
						relativePath: { eq: "clientsIcons/clientIcon25.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon26: file(
						relativePath: { eq: "clientsIcons/clientIcon26.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon27: file(
						relativePath: { eq: "clientsIcons/clientIcon27.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon28: file(
						relativePath: { eq: "clientsIcons/clientIcon28.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon29: file(
						relativePath: { eq: "clientsIcons/clientIcon29.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon30: file(
						relativePath: { eq: "clientsIcons/clientIcon30.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon31: file(
						relativePath: { eq: "clientsIcons/clientIcon31.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon32: file(
						relativePath: { eq: "clientsIcons/clientIcon32.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon33: file(
						relativePath: { eq: "clientsIcons/clientIcon33.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon34: file(
						relativePath: { eq: "clientsIcons/clientIcon34.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon35: file(
						relativePath: { eq: "clientsIcons/clientIcon35.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon36: file(
						relativePath: { eq: "clientsIcons/clientIcon36.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon37: file(
						relativePath: { eq: "clientsIcons/clientIcon37.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon38: file(
						relativePath: { eq: "clientsIcons/clientIcon38.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
					clientIcon39: file(
						relativePath: { eq: "clientsIcons/clientIcon39.png" }
					) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE)
						}
					}
				}
			`}
			render={data => {
				const mobileSliderSetting = {
					slidesToShow: 7,
					slidesToScroll: 3,
					swipeToSlide: true,
					rows: 3,
					infinite: false,
				}

				const tabletSliderSetting = {
					slidesToShow: 9,
					slidesToScroll: 3,
					swipeToSlide: true,
					rows: 3,
					infinite: false,
				}

				let images = {}

				Object.keys(data).forEach(
					category =>
						(images[category] = data[category].childImageSharp.gatsbyImageData)
				)

				return (
					<div className={styles.clientsIconsContainer}>
						<div className={styles.clientsIcons}>
							{/* Эта лишняя обертка нужна для позиционирования якоря*/}
							<div className={styles.clientsIcons__contentWrapper}>
								<div className={styles.clientsIcons__contentWrapper_desktop}>
									{Object.keys(data).map(client => (
										<GatsbyImage
											key={client}
											className={styles.clientsIcons__category__image}
											image={images[client]}
											alt={client}
										/>
									))}
								</div>
								<Slider
									className={styles.clientsIcons__slider_mobile}
									{...mobileSliderSetting}
								>
									{Object.keys(data).map(category => (
										<div
											className={styles.clientsIcons__mobile_imageContainer}
											key={category}
										>
											<GatsbyImage
												className={styles.clientsIcons__category__image}
												image={images[category]}
												alt={category}
											/>
										</div>
									))}
								</Slider>
								<Slider
									className={styles.clientsIcons__slider_tablet}
									{...tabletSliderSetting}
								>
									{Object.keys(data).map(category => (
										<div
											className={styles.clientsIcons__mobile_imageContainer}
											key={category}
										>
											<GatsbyImage
												className={styles.clientsIcons__category__image}
												image={images[category]}
												alt={category}
											/>
										</div>
									))}
								</Slider>
							</div>
						</div>
					</div>
				)
			}}
		/>
	)
}
