import { externalLinks } from "../common/links"

export default {
	title: "Наши клиенты",
	descriptionFirstPart:
		"Нашу систему устанавливают самые разные форматы общепита: рестораны, кофейни, фудтраки, столовые, бары, киоски в парке, сетевые точки и франшизы.",
	descriptionSecondPart:
		"На одном кассовом терминале Quick Resto выбивается до\n200 чеков за 17 минут — \nэто проверили в магнитогорской ",
	descriptionThirdPart:
		"А в МГТУ им. Н.Э. Баумана Quick Resto обслуживает все 43 точки общепита.",
	postLinks: {
		metallurg: {
			href: `${externalLinks.blog}keys-khokkeynaya-arena-metallurg-mangitogorsk/`,
			text: "арене «Металлург».",
		},
	},
	imageAlt: "Технологические карты блюд",
}
